@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Chivo:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "GeneralSans-Light";
  src: url("../public/fonts/GeneralSans-Light.woff2") format("woff2"),
    url("../public/fonts/GeneralSans-Light.woff") format("woff"),
    url("../public/fonts/GeneralSans-Light.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "GeneralSans-Regular";
  src: url("../public/fonts/GeneralSans-Regular.woff2") format("woff2"),
    url("../public/fonts/GeneralSans-Regular.woff") format("woff"),
    url("../public/fonts/GeneralSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "GeneralSans-Medium";
  src: url("../public/fonts/GeneralSans-Medium.woff2") format("woff2"),
    url("../public/fonts/GeneralSans-Medium.woff") format("woff"),
    url("../public/fonts/GeneralSans-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "GeneralSans-Semibold";
  src: url("../public/fonts/GeneralSans-Semibold.woff2") format("woff2"),
    url("../public/fonts/GeneralSans-Semibold.woff") format("woff"),
    url("../public/fonts/GeneralSans-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "GeneralSans-Bold";
  src: url("../public/fonts/GeneralSans-Bold.woff2") format("woff2"),
    url("../public/fonts/GeneralSans-Bold.woff") format("woff"),
    url("../public/fonts/GeneralSans-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

body {
  @apply leading-[normal] m-0;
}

*,
::before,
::after {
  border-width: 0;
}

:root {
  --toastify-icon-color-error: #fff !important;
  --toastify-icon-color-success: #fff !important;
}

/* React Toastify Customize */
.Toastify__toast {
  background-color: #1f2b49 !important;
}

.Toastify__toast-body {
  color: #fff;
}

.Toastify__progress-bar--error {
  background: linear-gradient(
    90deg,
    #ff5468 0%,
    #fcffee 49%,
    #ffffff 100%
  ) !important;
}

.Toastify__progress-bar--success {
  background: linear-gradient(
    90deg,
    #82f7ff 0%,
    #baffd4 49%,
    #fff 100%
  ) !important;
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  color: #fff !important;
  background-color: #fff !important;
}

.Toastify__close-button {
  color: #fff !important;
}

/* END React Toastify Customize */

h1 {
  font-family: "GeneralSans-Bold";
  font-size: clamp(50px, 11vw, 80px);
  line-height: 120%;
  letter-spacing: 0.02em;
}

h2 {
  font-family: "GeneralSans-Regular";
  font-size: 30px;
  line-height: 120%;
  letter-spacing: 0.02em;
}

h3 {
  font-family: "GeneralSans-Bold";
  font-size: clamp(45px, 6vw, 60px);
  line-height: 120%;
  letter-spacing: 0.02em;
}

h4 {
  font-family: "GeneralSans-Regular";
  font-size: 24px;
  line-height: 120%;
  letter-spacing: 0.04em;
}

h5 {
  font-family: "GeneralSans-Medium";
  font-size: 18px;
  line-height: 120%;
  letter-spacing: 0.04em;
}

a {
  @apply text-pink no-underline;
}

.label1 {
  font-family: "GeneralSans-Regular";
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.02em;
}

.label2 {
  font-family: "GeneralSans-Medium";
  font-size: 12px;
  line-height: 120%;
  text-transform: uppercase;
  letter-spacing: 0.18em;
}

.body {
  font-family: "GeneralSans-Medium";
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.03em;
}

.title-gradient {
  color: transparent; /* Hide the original placeholder */
  background-image: linear-gradient(
    90deg,
    #baffd4 0%,
    #82f7ff 49%,
    #ffd3d8 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
}

.interactive-gradient {
  color: transparent; /* Hide the original placeholder */
  background-image: linear-gradient(
    45deg,
    #e7fff0 0%,
    #feffff 49%,
    #c0d6e1 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
}

.interactive-gradient-bg {
  background: linear-gradient(45deg, #e7fff0 0%, #feffff 49%, #c0d6e1 100%);
}

.bg-glass {
  background: linear-gradient(45deg, #1f2b49 0%, #52596c 100%);
}

.sport-types {
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: color 0.3s ease;
}

.sport-types::before,
.sport-types::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  transition: opacity 0.3s ease;
}

.sport-types::before {
  background: linear-gradient(45deg, #1f2b49 0%, #52596c 100%);
  opacity: 1;
}

.sport-types::after {
  background: linear-gradient(45deg, #e7fff0 0%, #feffff 49%, #c0d6e1 100%);
  opacity: 0;
}

.sport-types:hover::before {
  opacity: 0;
}

.sport-types:hover::after {
  opacity: 1;
}

/* Adjust text color for better visibility on hover */
.sport-types {
  color: white; /* Default text color for dark background */
}

.sport-types:hover h5 {
  color: #1f2b49; /* Darker text color for light background on hover */
}

.courtFull:hover {
  background: linear-gradient(45deg, #e7fff0 0%, #feffff 49%, #c0d6e1 100%);
}

.courtFull:hover .courtPrice {
  background: #fff;
}

.courtFull:hover .price {
  color: #161e33;
}

select {
  font-family: "GeneralSans-Regular";
  padding: 20px 16px;
  border-radius: 16px;
  border: 1px solid #fff; /* Initial transparent border */
  background-color: transparent; /* Ensures background is transparent */
  width: 100%;
  color: #fff;
  font-size: 16px;
  letter-spacing: 0.05em;

  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("/public/ggchevrondowno.svg");
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 50%;
  cursor: pointer;
}

option {
  font-family: "GeneralSans-Regular" !important;
  padding: 20px 16px;
  border-radius: 16px;
  border: 1px solid #fff;
  background-color: transparent;
  width: 100%;
  color: #000000;
  font-size: 16px;
  letter-spacing: 0.05em;
}

input {
  font-family: "GeneralSans-Regular";
  padding: 20px 16px;
  border-radius: 16px;
  border: 1px solid #fff; /* Initial transparent border */
  background-color: transparent; /* Ensures background is transparent */
  width: 100%;
  color: #fff;
  font-size: 16px;
  letter-spacing: 0.05em;
  /* outline-color: #FB709A; */
}

/* Placeholder styling */
input::placeholder {
  color: transparent; /* Hide the original placeholder */
  background-image: linear-gradient(
    90deg,
    #baffd4 0%,
    #82f7ff 49%,
    #ffd3d8 100%
  );
  -webkit-background-clip: text;
  opacity: 0.35;
  background-clip: text;
  color: transparent; /* Ensures text gradient */
  font-size: inherit; /* Keeps placeholder font consistent with input font */
}

.btnPrimary {
  position: relative;
  padding-block: 19px;
  width: 100%;
  border-radius: 16px;
  color: #161e33;
  letter-spacing: 0.05em;
  overflow: hidden;
  z-index: 1;
}

.btnPrimary::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, #e7fff0 0%, #feffff 49%, #c0d6e1 100%);
  z-index: -1;
  transition: opacity 0.3s ease;
}

.btnPrimary::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  opacity: 0;
  z-index: -1;
  transition: opacity 0.3s ease;
}

.btnPrimary:hover::before {
  opacity: 0;
}

.btnPrimary:hover::after {
  opacity: 1;
}

.btnPrimary:disabled::before {
  background: #1f2b49;
  opacity: 0.9;
}
.btnPrimary:disabled .body {
  color: #b9b9b9 !important;
}

.MuiPickersDay-root.MuiPickersDay-dayWithMargin,
.MuiDayCalendar-weekDayLabel {
  color: #fff;
  font-size: 18px;
  font-family: "GeneralSans-Regular";
}

.Mui-disabled:not(.Mui-selected) {
  color: #1f2b49 !important;
}

.MuiPickersDay-root.MuiPickersDay-dayWithMargin.MuiPickersDay-today {
  /* border-color: #fff; */
  background-color: transparent;
  border: 1px solid #fb709a;
  color: #fb709a;
  font-family: "GeneralSans-Regular";
}

.MuiDateCalendar-root {
  width: 100%;
  min-height: 400px;
  border: 1px solid #424f6f;
  border-radius: 16px;
  padding: 24px;
}

.MuiDayCalendar-header {
  justify-content: space-between;
}

.MuiDayCalendar-weekContainer {
  justify-content: space-between;
  margin-top: 10px;
}

.MuiPickersCalendarHeader-labelContainer {
  font-size: 24px;
  font-family: "GeneralSans-Regular";
  line-height: 100%;
  letter-spacing: 0.04em;
  color: #fb709a;
}

.MuiPickersCalendarHeader-root {
  padding-left: 0px !important;
  padding-bottom: 35px;
  border-bottom: 1px solid #fff;
}

/*

.MuiDayCalendar-monthContainer {
  top: 30px;
}

.MuiPickersCalendarHeader-root {
  padding: 0;
  margin-bottom: 30px;
} */

.timing:hover {
  background-color: #cbf0d9;
  color: #071422;
}

.timing.active {
  background-color: #cbf0d9;
  color: #071422;
}

.icon-hover {
  display: none;
}

.timing.active .icon-hover {
  display: flex;
}

.timing:hover .icon-hover {
  display: flex;
}

.timing:hover .icon {
  display: none;
}

/* width */
.select-timings::-webkit-scrollbar {
  height: 5px !important;
}

/* Track */
.select-timings::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 4px;
}

/* Handle */
.select-timings::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 4px;
}

/* Handle on hover */
.select-timings::-webkit-scrollbar-thumb:hover {
  background: #bdbdbd;
}

.Mui-disabled:not(.Mui-selected) svg {
  color: #1f2b49 !important;
}
